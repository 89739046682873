import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import Swal from 'sweetalert2';
import { FormsModule } from '@angular/forms';
import { Loading } from 'notiflix';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';
// import { IonicModule } from '@ionic/angular';


@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, NgxMaskIonicModule],
  selector: 'mm12-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})


export class HeaderComponent implements OnInit {
  selectedLeave: string = '';
  public background: string = '#ffffff';
  public headerLinks = [
    { "type": "upper", "link": "<img src='../../../assets/imgs/header/faleConosco.png'> Fale Conosco", "route": "/faleconosco", "quarentineCanSee": false },
    { "type": "upper", "link": "<img src='../../../assets/imgs/header/faq.png'> Dúvidas Frequentes", "route": "/faq", "quarentineCanSee": false },
    { "type": "main", "link": "Home", "route": "/home", "quarentineCanSee": true },
    { "type": "main", "link": "O Programa", "route": "/programa", "quarentineCanSee": false },
    { "type": "main", "link": "Performance", "route": "/ranking", "quarentineCanSee": false },
    { "type": "main", "link": "Você Sabia?", "route": "/noticias", "quarentineCanSee": false },
    { "type": "main", "link": "Missões", "route": "/missoes", "quarentineCanSee": false },
    { "type": "main", "link": "Resgates", "route": "/resgates", "quarentineCanSee": true },
    { "type": "main", "link": "Treinamentos", "route": "/treinamentos", "quarentineCanSee": false },
    // { "type": "main", "link": "Admin", "route": "/backoffice" }, 
    { "type": "right", "link": "Minha Conta", "route": "/minhaconta", "quarentineCanSee": true },
    { "type": "right", "link": "Extrato", "route": "/extrato", "quarentineCanSee": true }
  ]
  public isLogged = false;
  public avatar = "../../../assets/imgs/header/avatar.png";
  public loginToggle = false;
  public primeiroAcesso = false;
  public loginPayload = { "id": "", "password": "", "confirmPassword": "", "accessCode": "" };

  public balance$!: Observable<any>;
  public points: any = 'Buscando Pontos...';
  public showMenu = false;

  isOpenMenu = false;
  isOpenNotification = false;
  trainings: any = [];
  user: any = {};

  isMobile = window.innerWidth < 1024;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.isMobile = window.innerWidth < 1024;
  }


  constructor(
    public router: Router,
    private auth: AngularFireAuth,
    private db: AngularFirestore,
    private st: AngularFireStorage,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("User: ", user.uid)
        this.loginToggle = false;
        this.user = (await this.db.collection('users').doc(user.uid).ref.get()).data();
        if (this.user) {
          let allTrainings: any = (await this.db.collection('treinamentos').doc("ebooks").ref.get()).data();
          this.trainings = allTrainings?.ebooks

          if (this.user.isAdmin) {
            this.headerLinks.push({ "type": "main", "link": "Admin", "route": "/backoffice", "quarentineCanSee": false })
          }
          if(this.user.quarentined) {
            this.headerLinks = this.headerLinks.filter(h => h.quarentineCanSee)
            if(Math.abs(this.user?.quarentinedAt?.seconds*1000 - new Date().getTime()) > 90*24*60*60 ) {
              alert("Sua conta está desativada e o período de quarentena acabou.");
              this.isLogged = false;
              this.auth.signOut();
              this.router.navigate(['/login']);
            }
          }
          if (this.user.active) {
            this.balance$ = this.db.collection('balances').doc(this.user.id).snapshotChanges().pipe(map((d: any) => { return (d.payload.data() ? d.payload.data().balance : 0); }));
            try {
              this.points = (await this.db.collection('apuracoes').ref.where('matricula', '==', this.user.loginId).where('points', '>', 0).where('ano', '==', new Date().getFullYear()).get()).docs.reduce((acc, doc: any) => acc += doc.data().points, 0)
            } catch(err) {
              console.log("Erro de saldo: ", err)
              this.points = 0;
            }
            this.isLogged = true;
          } else {
            alert("Sua conta foi desativada.");
            this.logout()
          }
        }
        Loading.remove();
      } else {
        this.user = {};
        if (this.router.url != "/voucher") {
          this.router.navigate(['/login']);
        }
      }
    })
  }

  public login() {
    let env = this;
    if (!this.loginPayload.id || !this.loginPayload.password) {
      return Swal.fire({
        title: 'Erro',
        text: 'Preencha todos os campos para continuar',
        icon: 'error',
        heightAuto: false
      });
    }

    return this.db.collection('preLogin', ref => ref.where('cpf', '==', this.loginPayload.id)).get().pipe(
      switchMap((snapshot: any) => {
        Loading.hourglass();
        return this.auth.signInWithEmailAndPassword(snapshot?.docs[0]?.data()?.email, this.loginPayload.password)
          .then((usr) => {
            this.db.collection('users').doc(usr.user.uid).ref.get().then((u: any) => {
              if (u.exists) {
                if (u.data().active) {
                  if(u.data().quarentined && Math.abs(u.data()?.quarentinedAt?.seconds*1000 - new Date().getTime()) > 90*24*60*60 ) {
                    env.presentError('Ops', 'Sua conta foi desativada e o período de quarentena se acabou.')
                    this.auth.signOut();
                    this.router.navigate(['/login']);
                  } else {
                    this.isLogged = true;
                    this.router.navigate(['/home']);
                  }
                } else {
                  env.presentError('Ops', 'Sua conta foi desativada, entre em contato com o SAC.')
                }
              } else {
                env.presentError('Ops', 'Sua conta ainda não foi ativada, entre em contato com o SAC.')
              }
            })
          })
          .catch(err => {
            console.log(err);
            Swal.fire({
              title: 'Ocorreu um erro ao realizar login',
              text: this.getErrorMessage(err.code),
              icon: 'error',
              heightAuto: false
            })
          });
      }),
      finalize(() => {
        Loading.remove();
      })).subscribe();
  }

  public activate() {
    let env = this;
    Loading.hourglass();

    if (Object.values(env.loginPayload).some(val => !val)) { env.presentError('Ops', 'Preencha todos os campos para continuar.'); return; }
    if (env.loginPayload.password != env.loginPayload.confirmPassword) { env.presentError('Ops', 'As senhas inseridas não são iguais.'); return; }
    if (env.loginPayload.password.length < 6) { env.presentError('Ops', 'Sua senha deve ter ao menos 6 caracteres.'); return; }
    console.log(env.loginPayload.id);
    env.db.collection('preLogin').doc(env.loginPayload.id).ref.get().then((doc: any) => {
      if (doc.exists) {
        console.log(doc.data())
        if (doc.data().hasLoggedIn) { env.presentError('Ops', 'Sua conta já foi ativada antes. Faça o login normalmente.') }
        else if (!doc.data().cnpj) { env.presentError('Ops', 'Sua conta não está vinculada a um CNPJ, entre em contato com o SAC.') }
        else if (doc.data().accessCode != env.loginPayload.accessCode) { console.log(doc.data().accessCode); console.log(doc.data().accessCode); env.presentError('Ops', 'Código de acesso inválido.') }
        else {
          env.db.collection('usersCNPJ').ref.where('cpfs', 'array-contains', env.loginPayload.id).where('active', '==', true).get().then((cnpjUsers) => {
            if (cnpjUsers.empty) {
              env.presentError('Ops', 'Sua conta não pertence a um CNPJ ativo, entre em contato com o SAC.')
            } else {
              env.auth.createUserWithEmailAndPassword(doc.data().email, env.loginPayload.password).then(usr => {
                env.db.collection('preLogin').doc(env.loginPayload.id).update({ "hasLoggedIn": true }).then(() => {
                  env.db.collection('accessLogs').doc(new Date().getTime().toString()).set({
                    "category": doc.data().category,
                    "cnpj": doc.data().cnpj,
                    "cpf": doc.data().cpf,
                    "email": doc.data().email,
                    "userId": usr.user.uid,
                    "loginId": doc.data().loginId,
                    "createdAt": new Date(),
                  }).then(() => {
                    let userObj = {
                      "category": doc.data().category,
                      "cnpj": doc.data().cnpj,
                      "cpf": doc.data().cpf,
                      "email": doc.data().email,
                      "id": usr.user.uid,
                      "loginId": doc.data().loginId,
                      "createdAt": new Date(),
                      "active": true
                    }
                    env.db.collection('users').doc(usr.user.uid).set(userObj).then(() => {
                      env.user = userObj;
                      env.isLogged = true;
                      env.router.navigate(['/minhaconta']);
                    }).catch(err => { env.presentError('Ops', 'Erro ao criar seu usuário, entre em contato com o SAC.') })
                  }).catch(err => { env.presentError('Ops', 'Erro ao registrar seu primeiro login, entre em contato com o SAC.') })
                }).catch(err => { env.presentError('Ops', 'Erro ao ativar seu login, entre em contato com o SAC.') })
              }).catch(err => { env.presentError('Ops', 'Erro ao ativar usuário, entre em contato com o SAC: ' + err.code ? err.code : JSON.stringify(err)) })
            }

          }).catch(err => { env.presentError('Erro', 'Erro ao buscar dados do CNPJ, verifique sua conexão e tente novamente.') })
        }
      } else { env.presentError('Ops', 'Sua conta não foi encontrada na base de usuários elegíveis.') }
    }).catch(err => { env.presentError('Erro', 'Erro ao buscar dados de ativação, verifique sua conexão e tente novamente.') })
  }

  presentError(title, text) {
    Loading.remove();
    Swal.fire({ 'title': title, 'text': text, 'icon': 'error', 'heightAuto': false });
  }

  getErrorMessage(err) {
    switch (err) {
      case 'auth/user-not-found':
        return '1 - Você ainda não está cadastrado.';
      case 'auth/wrong-password':
        return 'CPF ou Senha incorretos.';
      default:
        return 'Ocorreu um erro ao tentar fazer login, verifique seus dados e tente novamente.';
    }
  }

  logout() {
    this.isOpenMenu = false;
    this.auth.signOut().then(() => {
      this.isLogged = false;
      this.router.navigate(['/login']);
    });
  }


  toggleLogin(bool) {
    this.loginToggle = bool;
  }

  filterLinks(filter) {
    return this.headerLinks.filter(link => link.type === filter);
  }

  getFirstAndLastName(fullName) {
    if (!fullName) { return null }
    else { return fullName.split(' ')[0] + " " + fullName.split(' ')[fullName.split(' ').length - 1]; }
  }

  headerLinkClick(str) {
    let env = this;
    if (str == '/carrinho') {
      return alert("Em breve.")
    } else if (str == '/treinamentos') {
      if (env.user.un && env.trainings?.length) {
        console.log("env.trainings: ", env.trainings)
        if (env.trainings.filter(t => t.un == env.user.un)?.length || env.user.un == "ADMIN") {
          let swalHtml = "";
          let filteredTrainings = env.user.un == "ADMIN" ? env.trainings : env.trainings.filter(t => t.un == env.user.un)
          for (let i = 0; i < filteredTrainings.length; i++) {
            swalHtml = swalHtml + '<a target="_blank" href="' + filteredTrainings[i].link + '">' + filteredTrainings[i].title + '</a><br>';
          }
          Swal.fire({ 'title': 'Escolha um treinamento:', 'html': swalHtml, 'heightAuto': false, 'confirmButtonColor': '#fdba12' });

          // window.open(env.trainings.find(t => t.un == env.user.un).link, '_blank');
        } else {
          return alert("Nenhum treinamento encontrado para sua unidade de negócio.")
        }
      } else {
        return alert("Nenhum treinamento encontrado para sua unidade de negócio.")
      }
    } else {
      env.isOpenMenu = false;
      env.router.navigate([str]);
    }
  }

  getPhoto() {
    document.getElementById('profileImgInput').click();
  }

  savePhoto(e) {
    const ref = this.st.ref('users/' + this.user.id + '/profile');
    const file = e.target.files[0];
    Loading.hourglass();
    if (file) {
      ref.put(file).then(async (d) => {
        try {
          const url = await d.ref.getDownloadURL();
          await this.db.collection('users').doc(this.user.id).update({ "avatar": url }).catch(err => {
            Loading.remove();
            this.presentError('Erro', 'Erro ao salvar foto, verifique sua conexão e tente novamente.');
          });
          Loading.remove();
          this.user.avatar = url;
        } catch (err) {
          Loading.remove();
          this.presentError('Erro', 'Erro ao salvar foto, verifique sua conexão e tente novamente.');
        }
        Loading.remove();
      }).catch(err => {
        Loading.remove();
        this.presentError('Erro', 'Erro ao salvar foto, verifique sua conexão e tente novamente.');
      });
    }
  }

  forgotPassword() {
    let env = this;
    if (this.loginPayload.id == "") { env.presentError('Ops', 'Insira seu CPF e clique novamente em "Esqueceu sua senha".'); return; }

    Loading.hourglass();
    this.db.collection('preLogin').doc(this.loginPayload.id).ref.get().then((doc: any) => {
      if (doc.exists) {
        if (doc.data().email) {
          env.auth.sendPasswordResetEmail(doc.data().email).then(() => {
            Loading.remove();
            Swal.fire({ 'title': 'Sucesso!', 'text': 'Verifique sua caixa de email para redefinir sua senha.', 'icon': 'success', 'heightAuto': false });
          }).catch(err => {
            env.presentError('Ops', 'Erro ao enviar email de redefinição de senha: ' + JSON.stringify(err))
          })
        } else {
          env.presentError('Ops', 'Não foi possível encontrar seu email registrado no sistema. Entre em contato com o SAC.')
        }
      } else {
        env.presentError('Ops', 'CPF não encontrado.')
      }
    }).catch(err => {
      env.presentError('Ops', 'Erro ao buscar dados: ' + JSON.stringify(err))
    })
  }

  resendCode() {
    let env = this;
    if (this.loginPayload.id == "") { env.presentError('Ops', 'Insira seu CPF e clique novamente em "Não recebeu seu código de acesso".'); return; }

    Loading.hourglass();
    this.db.collection('preLogin').doc(this.loginPayload.id).ref.get().then((doc: any) => {
      if (doc.exists) {
        if (doc.data().email) {
          let postBody = {
            "emailsObjs": [{
              "email": doc.data().email,
              "dinamicData": {
                "html": "<h1>Seu código de acesso Sotreq:</h1><h2><b>" + doc.data().accessCode + "</b></h2>",
                "subject": "Seu código de acesso Sotreq"
              }
            }],
            "remetente": "suporte@fidelidadesotreq.com.br",
            "nome": "Suporte Fidelidade Sotreq",
            "templateId": "d-9254cae622f0495a8acb7118ffbc849c",
            "apiKey": "contato@mm12.com.br"
          };

          this.http.post('https://us-central1-mm12-storage.cloudfunctions.net/EnviarEmails', postBody).toPromise().then((res) => {
            Loading.remove();
            Swal.fire({ 'title': 'Sucesso!', 'text': 'Verifique sua caixa de email para redefinir sua senha.', 'icon': 'success', 'heightAuto': false });
          }).catch(err => {
            env.presentError('Ops', 'Erro ao enviar email com código de acesso: ' + JSON.stringify(err))
          })
        } else {
          env.presentError('Ops', 'Não foi possível encontrar seu email registrado no sistema. Entre em contato com o SAC.')
        }
      } else {
        env.presentError('Ops', 'CPF ou CNPJ não encontrado.')
      }
    }).catch(err => {
      env.presentError('Ops', 'Erro ao buscar dados: ' + JSON.stringify(err))
    })
  }

  openRegulamento() {
    if (this.user) {
      this.db.collection('config').doc('regulamento').ref.get().then(doc => {
        if (doc.exists) {
          if (doc.data()[this.user.un]) {
            window.open(doc.data()[this.user.un], '_blank')
          }
        }
      })
    }
  }

  @ViewChild('popover') popover;

  isOpen = false;

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  public menu() {
    this.showMenu = !this.showMenu;
  }

  openTraining() {
    alert(1)
  }

}
